.units{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;

    

    .title_units{
        font-size: 20px;
        font-weight: 600;
        margin-top: 10px;
    }
    .selected{
        background: #29550394 !important;
    }
    .unit{
        display: flex;
        flex-direction: row;
        width: 95%;
        align-items: center;
        background: #262a2e;
        border-radius: 15px;
        border: 1px solid #4e4f50;
        justify-content: space-between;
        
        .icon{
            width: 50px;
        }
        .action_icon{
            width: 20px;
        }
        .info_container_units{
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}