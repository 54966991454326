$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);
$primary-color: #1a1c2b;
$gradient-color: #3d4b9b; 
$glow-color: rgba(61, 75, 155, 0.6); 


.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 80vh;
  justify-content: space-around;
  background: #1b1e22;
  border-radius: 30px 30px 0 0;
  border-top: 3px solid rgb(236, 181, 40);
  box-shadow: 0px -5px 20px 0px rgba(236, 181, 40, 0.81);
}

@keyframes playZoneAnimation {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(0.97, 0.97, 0.97) ;
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.play-zone {
  position: relative;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  width: 85%;
  &.animate {
    animation: playZoneAnimation 0.3s ease-in-out;
  }

  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, #525ae3 -50%, #1d2228);
    background-clip: padding-box;
    width: 96%;
    height: 97%;
    border-radius: 50%;
  }
}

.inner-circle {
  width: 90%;
  height: 90%;
  background: radial-gradient(circle at center, #2d3e8b 25%, #2a385f 50%, #282e44 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  img {
    width: 80%;
    height: auto;
    border-radius: 50%;
  }
}



.speed-up {
  animation: play 0.2s steps(10) infinite;
}

@keyframes play {
  100% {
      background-position: -1000px;
  }
}

.outer-container_home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  margin-bottom: -20px;
  flex-direction: column;
}



.container_p40 {
  width: calc(100% - 2*$border-width); 
  height: calc(100% - 2*$border-width);
  padding: 5vw;
  margin: $border-width;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #111111; 
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  position: relative;
  z-index: 1; 
  
}

.header_pet {
  margin: 5vw;
  background: linear-gradient(90deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
  border-radius: 10px;
  padding: 12px;
  text-decoration: none;
  color: white;
  min-height: 64px;
}

.balance_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  h2 {
    font-size: 20px;
    font-size: 400;
    text-align: center;
  }
}

.row_home {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
}
.tg_btn{
    background: #282B30;
    padding: 5px;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.glow_effect_container {
    width: 100vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    background: #111111;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

.stats {
  display: flex;
  gap: 15px;
}

.stats-coin {
    width: 65px;
    height: auto;
}
.coin-count {
    font-size: 48px;
    font-weight: 700;
    margin-left: -7px;
}
.energy {
  height: 100%;
  background: linear-gradient(90deg, #FB4FFF 25%, #25B1FF 61%);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.1s ease;
}
.energy-wrapper {
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7vh;
}

.iconHome {
  width: 36px;
}

.energy-counter {
  margin-right: 4px;
  font-weight: 700;
  font-size: 16px;

  span {
    font-size: 24px;
  }
}

.energy-bar {
  width: 100%;
  height: 18px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(90deg, #7CFF6B, #D7BFFF, #70A0FF);
}

@media screen and (max-height: 622px) {
  .energy-counter {
    font-size: 14px;
    span {
      font-size: 20px;
    }
  }

  .energy-wrapper {
    gap: 3px;
  }

  .energy-bar {
    height: 12px;
  }
}

.boost_btn_img {
  width: 25px;
  margin-bottom: 5px;
}
.boost_btn {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  gap: 10px;
}
.league_text {
  font-size: 1rem;
  color: white;
  text-decoration: none;
  font-weight: 300;
}
.league_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  z-index: 1000;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.gameplay-coin img {
  width: 65vw;
  max-width: 450px;
  height: auto;
  transition: transform 0.1s ease-out;
  perspective: 1000px;
  z-index: 1;
  position: relative;
}
.header{
  flex-direction: row;
}
.settings {
  width: 26%;
  height: 100%;
  background: linear-gradient(270deg, rgb(96 118 223), rgb(157 141 234));
  border-radius: 10px;
  padding: 6px;
  text-decoration: none;
  color: white;
  min-height: 64px;
}
.setting_logo{
  border-radius: 10px;
  height: 40px;
  object-fit: cover;
  width: 40px;
}
.setting_name{
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
.img_clicker {
  transition: transform 100ms ease-in-out;
}

.img_clicker.scale {
  transform: scale(0.99);
}
.row_home {
  display: flex;
  flex-direction: row !important;
}

.level-indicator {
  color: #fff;
  padding: 0 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.level-info {
  display: flex;
  align-items: center;
}
.level_container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.progress_bar_home {
  width: 100%;
  height: 10px;
  background: rgba(213, 213, 213, 0.4117647059);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
}
.progress_home {
  height: 100%;
  background: linear-gradient(90deg, #A4E1A8 33%, #C88EF2 66%, #7068F6 100%);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.1s ease;
}

.trophy-icon {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.level-name {
  font-weight: bold;
}

.level-arrow {
  margin-left: 5px;
}

.progress-bar {
  background-color: #404954;
  flex-grow: 1;
  height: 10px;
  border-radius: 5px;
  margin: 0 20px;
  position: relative;
}

.progress-filled {
  background: linear-gradient(to right, #6e7ff3, #c158dc);
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.level-text {
  min-width: 60px;
  text-align: center;
}

.levels {
  font-size: 15px;
  font-weight: 700;
  position: absolute;
  right: 20px;
}

.leagueImg {
  width: 30px;
  height: 30px;
}

.leagueContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .tab-button-arrow {
    margin-left: 0;
    justify-content: start;
    align-items: center;
    display: flex;
    color: white;
  }
}

.leagueName {
  font-weight: 700;
  font-size: 20px;
  color: #CACACA;
}
.dailyBlock {
  display: flex;
  flex-direction: row;
  gap: 7px;
  width: 95%;
  justify-content: space-between;
}

.daily{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #272a2f;
  width: 100%;
  border-radius: 10px;
  gap: 5px;

  img{
    width: 45px;
  }
  name{
    font-size: 10px;
    font-weight: 700;
  }

}
.header_home {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skins{
  display: flex;
  flex-direction: row;
  gap: 5%;

  img{
    height: 50px;
  }
  .buycontainer{
    position: relative;

    .buy_text{
      position: absolute;
      left: 0;
      font-size: 14px;
      top: 0;
      font-weight: 600;
    }
  }
}
.flex_end {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  .profit_container{
    width: 65%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5%;
    background: #272a2f;
    padding: 0 10px;
    border-radius: 15px;

    img{
      height: 20px;
    }

    .profit_text {
      font-size: 10px;
      width: max-content;
    }
  }
}
.flex_text {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 10px;
}
.leagueBarHome {
  width: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  background: #47402e;
  border-radius: 10px;
  margin-top: 6px;
}
.energyBarHome {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(90deg, #7CFF6B, #D7BFFF, #70A0FF);
}

.homeMain{
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.league_home{
  width: 30%;
}
.profitInfo{
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;

  img{
    height: 15px !important;
  }
}

@media screen and (max-height: 833px) {

  .gameplay-coin img {
    width: 40vw;
  }
}
@media screen and (max-height: 714px) {

  .play-zone {
    width: 70%;
  }

  .coin-count {
    font-size: 30px;
  }
  .stats-coin {
    width: 40px;
  }

  .daily img {
    width: 35px;
  }

  .daily {
    padding: 5px;
  }

  .gameplay-coin img {
    width: 47vw;
  }

  .energy-wrapper {
    margin-bottom: 10vh;
  }

  .iconHome {
    width: 25px;
  }

  .energy-counter {
    font-size: 12px;
  }

  .header_home {
    gap: 5px;

  }
}