.battle-bear-page {
    text-align: center;
    background-color: #000;
    color: #fff;
    font-family: 'Arial', sans-serif;
    height: 100vh;


    .bear-image {
      margin-bottom: 20px;
      position: relative;
  
      img {
            width: 100%;
            max-width: 500px;
            border-radius: 10px;
            max-height: 80vh;
      }

      .text_container{
        position: absolute;
        bottom: 10%;
        width: 100%;

        h1 {
           font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            color: #ffde59;
          }
      
          p {
            font-size: 1.5rem;
            margin-bottom: 0.2rem;
          }
      
          h2 {
            font-size: 4rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
          }
    }
    }
  
    .content {
      
  
      .socials {
        p {
          font-size: 1rem;
        }
  
        .icons {
          display: flex;
          justify-content: center;
          margin-top: 10px;
  
          a {
            margin: 0 15px;
            background: white;
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 50%;
            
            img {
              width: 30px;

            }
          }
        }
      }
    }
  }
  .loader-line {
    width: 70%;
    height: 6px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    margin: 10px auto;
    border-radius: 20px;
}

.loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 6px;
    width: 40%;
    background-color: coral;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}