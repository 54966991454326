.outer_container_wallet{
    height: 60vh;
    box-sizing: border-box;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    border-top: 3px solid rgb(236, 181, 40);
    box-shadow: 0px -5px 20px 0px rgba(236, 181, 40, 0.81);
    width: 100%;
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1c1f24;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    justify-content: space-evenly;
}


.button_container {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.button_thanks {
    width: 45%;
    min-height: 70px;
    padding: 12px 8px;
    color: white;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    background: #5a60fe;
    font-weight: 500;
    border-radius: 15px;
}

.button_decline {
    width: 45% !important;
    min-height: 70px;
    padding: 12px 8px;
    color: white;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    background: #ff4f4f;
    font-weight: 500;
    border-radius: 15px;
}


.content_wallet {
    display: flex;
    flex-direction: column;
    background: #272a2f;
    position: relative;
    border-radius: 15px;
    width: 100%;
    padding: 10px;
}

.title_wallet{
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 10px;
}
.subtext_wallet{
    text-align: center;
    margin-bottom: 20px;
}
.input_wallet {
    padding: 5px;
    border-radius: 15px;
    padding-left: 10px;
    border: 1px solid #1c1f24;
    background: #1c1f24;
    font-size: 15px;
    color: white;
}