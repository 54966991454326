.airdrop_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;


    .image_container{

        margin: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        
        img{
            width: 40%;
            z-index: 1;
        }

        .glow_airdrop{
            position: absolute;
            top: 50%;
            margin: auto;
            width: 20px;
            box-shadow: 0px 0px 50px 50px rgb(236 181 40);
            height: 20px;
            border-radius: 50%;
            z-index: 0;
        }
    }

    .title{
        margin-bottom: 5%;
        font-size: 30px;
        font-weight: 700;
    }

    .subtitle{
        text-align: center;
        font-weight: 300;
        margin-bottom: 5%;
    }
}

.content{
    width: 94%;
    margin: auto;

    .title{
        font-size: 17px;
        font-weight: 500;
    }

    .connect_button{
        padding: 10px;
        background: #24A1DE;
        border-radius: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 5%;
    }

    .wallet{
        width: 60px;
    }
    
    .arrow_airdrop{
        height: 20px;
    }


}