@media (max-width: 400px) {
     
    .bottom_section {
        .left_box img {
            width: 100px !important;
        }

        .right_box h2 {
            font-size: 18px !important; 
        }

        .right_box p {
            font-size: 12px !important; 
        }
    }

    .next_button {
        margin-top: 0px !important;
       
    }

    .next_button button {
        font-size: 14px !important; 
        margin-top: 0;
    }
}


.instruction_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    background-color: #1e1e1e;
    color: white;
    

    .image_main{
        height: 70%;
    }
    .bottom_instruction{
        border-top-left-radius: 15px;
        background: #262334;
        border-top-right-radius: 15px;
        position: absolute;
        bottom: 0;
        padding: 15px;
        height: 30%;
    }


    .bottom_section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        
        .left_box {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 140px;
                padding: 5px;
                border: 3px solid black;
                border-radius: 15px;
            }
        }

        .right_box {
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 20px;

            h2 {
                font-size: 24px;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    .next_button {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        button {
            background-color: #5d62f2;
            color: white;
            font-size: 18px;
            padding: 10px 30px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
        }
    }
}
